import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
    ApexAxisChartSeries,
    ApexNonAxisChartSeries,
    ApexChart,
    ChartComponent,
    ApexDataLabels,
    ApexXAxis,
    ApexPlotOptions,
    ApexLegend,
    ApexGrid,
    ApexYAxis,
    NgApexchartsModule,
    ApexResponsive,
    ApexTooltip,
    ApexStroke
} from "ng-apexcharts";
import { UtilityService } from 'src/app/core/services/utility.service';
import { UniDashboardService } from 'src/app/shared/services/uni-dashboard-service/uni-dashboard.service';
import { Router } from '@angular/router';


export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    xaxis: ApexXAxis;
    grid: ApexGrid;
    plotOptions: ApexPlotOptions;
    colors: string[];
    yaxis: ApexYAxis;
    legend: ApexLegend;
};

export type DonutChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
    dataLabels: ApexDataLabels;
    legend: ApexLegend;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    colors: string[];
};

@Component({
    selector: 'app-issues-summary',
    standalone: true,
    imports: [RouterLink, MatCardModule, MatButtonModule, MatMenuModule, NgApexchartsModule,MatTableModule,MatPaginator,MatCheckbox],
    templateUrl: './master-report.component.html',
    styleUrl: './master-report.component.scss'
})
export class MasterReportComponent implements OnInit {

    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
    public donutChartOptions: Partial<DonutChartOptions>;
    dataSource:MatTableDataSource<any>;
    displayedColumns: string[] = ['name','status',  'totalStudents', 'totalPatients'];

    receivedData: any;

    constructor(private uniDashboardService:UniDashboardService, private route: ActivatedRoute) {
        
    }
    ngOnInit(): void {
        const userInfo = UtilityService.getUser();
        this.uniDashboardService.FetchMasterReportData(userInfo.id).subscribe(
            (response)=>{
                console.log(response.body);
                var data = response.body;
                this.chartOptions = {
                    series: [
                        {
                            name: "Patients",
                            data: data.map((item: { totalPatients: any; }) => item.totalPatients)
                        },
                        {
                            name: "Students",
                            data: data.map((item: { totalStudents: any; }) => item.totalStudents)
                        }
                    ],
                    chart: {
                        type: "bar",
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false
                        }
                    },
                    colors: [
                        "#00836C", "#28a745"
                    ],
                    grid: {
                        show: true,
                        strokeDashArray: 0,
                        borderColor: "#edeff5"
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 7,
                            columnWidth: '30px',
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        axisBorder: {
                            show: false,
                            color: '#edeff5'
                        },
                        axisTicks: {
                            show: false,
                            color: '#edeff5'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: "#262626",
                                fontSize: "13px"
                            }
                        },
                        categories: data.map((item: { name: any; }) => item.name)
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            style: {
                                colors: "#a9a9c8",
                                fontSize: "13px"
                            }
                        },
                        axisBorder: {
                            show: true,
                            color: '#edeff5'
                        }
                    },
                    legend: {
                        show: false
                    }
                };
                console.log(data.map((item: { totalPatients: any; totalStudents: any; }) => this.formatRatio(item.totalStudents,item.totalPatients)))
                this.donutChartOptions = {
                    series: data.map((item: { totalPatients: any; totalStudents: any; }) => this.formatRatio(item.totalStudents,item.totalPatients)),
                    chart: {
                        type: "donut",
                        height: 400,
                    },
                    labels: data.map((item: { name: any; }) => item.name),
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        }
                    ],
                    legend: {
                        offsetY: 0,
                        position: "bottom",
                        horizontalAlign: "left",
                        fontSize: '13px',
                        labels: {
                            colors: '#77838f'
                        },
                        itemMargin: {
                            horizontal: 12,
                            vertical: 8
                        }
                    },
                    stroke: {
                        width: 0,
                        show: true
                    },
                    colors: [
                         
                    ],
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '14px',
                        },
                        dropShadow: {
                            enabled: false
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function(val:any) {
                                return val + "";
                            }
                        }
                    }
                };
                this.dataSource = new MatTableDataSource(data);
        })
        

       
    }

    // Search Filter
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    
    formatRatio(totalStudents: number, totalPatients: number): number {
        if (totalPatients === 0) {
            return 0; // Avoid division by zero
        }
        const ratio = totalStudents / totalPatients;
        return parseFloat((totalStudents / totalPatients).toFixed(1)); // Format to 1 decimal place
    }
    


    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

}

