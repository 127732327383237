<mat-card class="alert-card">
    <mat-card-header class="alert-header">
      <mat-card-title>Summary</mat-card-title>
    </mat-card-header>
    <mat-card-content class="alert-content">
        <div style="width: 60%;">
      <ul class="alert-list" id="alertList">
        <li><strong>Alert 1:</strong> {{opdNotComplianceCount}} Hospitals' OPD are out of compliance today</li>
        <li><strong>Alert 2:</strong> {{inpNotComplianceCount}} Hospitals' IPD are out of compliance today</li>
        <!-- <li><strong>Alert 3:</strong> Payment failed. Please update your payment method.</li>
        <li><strong>Alert 4:</strong> New feature available in your dashboard.</li>
        <li><strong>Alert 5:</strong> Your profile information is incomplete.</li> -->
      </ul>
    </div>
    <div style="width: 40%;">
      <img style="width: 50%; display: flex; justify-content: center;" src="./assets/images/parul-logo.png" alt="Logo" class="alert-logo">
    </div>
    </mat-card-content>
    <mat-icon (click)="toggleAlerts()">keyboard_arrow_down</mat-icon>
    <!-- <button mat-raised-button color="primary" id="showMoreBtn" class="show-more-btn" (click)="toggleAlerts()">
      Show More
    </button> -->
  </mat-card>
  