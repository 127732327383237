import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { retry, shareReplay } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  /**
   * Get Request
   * @param path
   * @param queryParms
   * @param baseUrl
   * @returns
   */
  public get(
    path: string,
    queryParms: any,
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    let httpParams: any;
    let httpHeaders: HttpHeaders = new HttpHeaders();
    Object.keys(queryParms).forEach((key) => {
      if (key === 'headers') {
        Object.keys(queryParms.headers).forEach((hkey) => {
          if (
            queryParms.headers[hkey] !== null &&
            queryParms.headers[hkey] !== ''
          ) {
            // tslint:disable-next-line: quotemark
            httpHeaders = httpHeaders.set(hkey, queryParms.headers[hkey]);
          }
        });
      } else {
        httpParams = this.getQueryParams(queryParms);
      }
    });
    return this.http
      .get(`${baseUrl + path}`, {
        headers: httpHeaders,
        observe: 'response',
        params: httpParams,
      })
      .pipe(retry(1), shareReplay());
  }

  public getImage(
    path: string,
    httpHeaders: any = {},
    baseUrl: string = environment.baseURL
  ): Observable<Blob> {
    return this.http
      .get(`${baseUrl + path}`, {
        headers: httpHeaders,
        responseType: 'blob', // Specify the response type as blob to handle binary data
      })
      .pipe(retry(1), shareReplay());
  }

  public getSingle<T>(
    path: string,
    httpHeaders: any = {},
    baseUrl: string = environment.baseURL
  ): Observable<T> {
    return this.http
      .get<T>(`${baseUrl + path}`, { headers: httpHeaders })
      .pipe(retry(1), shareReplay());
  }
  public post(
    path: string,
    requestObject: any,
    httpHeaders: any = {},
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    return this.http
      .post(`${baseUrl + path}`, requestObject, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(retry(0), shareReplay());
  }

  public patch(
    path: string,
    requestObject: any,
    httpHeaders: any = {},
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    return this.http
      .patch(`${baseUrl + path}`, requestObject, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(retry(0), shareReplay());
  }

  /**
   *
   * @param path
   * @param requestObject
   * @param httpHeaders
   * @param baseUrl
   * @returns
   */
  public postFormEncoded(
    path: string,
    requestObject: any,
    httpHeaders: any = {},
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    const body = new URLSearchParams();
    if (requestObject) {
      Object.keys(requestObject).forEach((obj) => {
        if (requestObject[obj] !== null || requestObject[obj] !== undefined) {
          body.set(obj, requestObject[obj]);
        }
      });
    }
    return this.http
      .post(`${baseUrl + path}`, body.toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        observe: 'response',
      })
      .pipe(retry(0), shareReplay());
  }

  /**
   * This is for upload File
   * @param path
   * @param requestObject
   * @param baseUrl
   * @returns
   */
  public uploadFile(
    path: string,
    requestObject: any,
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    return this.http
      .post(`${baseUrl + path}`, requestObject, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(retry(0), shareReplay());
  }

  /**
   * Put Request
   * @param path
   * @param requestObject
   * @param httpHeaders
   * @param baseUrl
   * @returns
   */
  public put(
    path: string,
    requestObject: any,
    httpHeaders: any = {},
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    return this.http
      .put(`${baseUrl + path}`, requestObject, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(retry(0), shareReplay());
  }

  /**
   * Delete Request
   * @param path
   * @param httpHeaders
   * @param baseUrl
   * @returns
   */
  public delete(
    path: string,
    requestObject: any,
    httpHeaders: any = {},
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    return this.http
      .delete(`${baseUrl + path}`, {
        headers: httpHeaders,
        observe: 'response',
        body: requestObject,
      })
      .pipe(shareReplay());
  }

  /**
   * This is for Query Parameter
   * @param queryParams
   * @returns
   */
  private getQueryParams(queryParams: any) {
    let queryString: HttpParams = new HttpParams();
    Object.keys(queryParams).forEach((param) => {
      if (param !== 'headers') {

        if(queryParams[param] !== null && queryParams[param] !== '' && Array.isArray(queryParams[param])){
          queryParams[param].forEach((item : any) => {
          queryString = queryString.append(param, item);
          });
        }
       else if (queryParams[param] !== null && queryParams[param] !== '') {
          const value = queryParams[param].toString().trim();
          queryString = queryString.append(param, value);
        }
      }
    });
    return queryString;
  }

  /**
   * Get Request
   * @param path
   * @param httpParams
   * @returns
   */
  public getRequest(path: string, httpParams: any) {
    const httpHeaders: HttpHeaders = new HttpHeaders();
    return this.http.get(`${environment.baseURL + path}`, {
      headers: httpHeaders,
      params: httpParams,
    });
  }

  /**
   * Custom Put Request
   * @param path
   * @param requestObject
   * @param httpHeaders
   * @returns
   */
  public customPut(
    path: string,
    requestObject: any,
    httpHeaders: any = {}
  ): Observable<any> {
    return this.http
      .put(path, requestObject, {
        headers: httpHeaders,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(retry(0), shareReplay());
  }

  /**
   * Custom Get Request
   * @param path
   * @param queryParms
   * @param baseUrl
   * @returns
   */
  public customGet(
    path: string,
    queryParms: any,
    baseUrl: string = environment.baseURL
  ): Observable<any> {
    let httpParams: any;
    let httpHeaders: HttpHeaders = new HttpHeaders();
    Object.keys(queryParms).forEach((key) => {
      if (key === 'headers') {
        Object.keys(queryParms.headers).forEach((hkey) => {
          if (
            queryParms.headers[hkey] !== null &&
            queryParms.headers[hkey] !== ''
          ) {
            // tslint:disable-next-line: quotemark
            httpHeaders = httpHeaders.set(hkey, queryParms.headers[hkey]);
          }
        });
      } else {
        //    httpParams = this.getQueryParams(queryParms);
      }
    });
    return this.http
      .get(`${baseUrl + path}`, {
        headers: httpHeaders,
        observe: 'events',
        params: httpParams,
      })
      .pipe(retry(0), shareReplay());
  }

  /**
   * Custom Post
   * @param path
   * @param requestObject
   * @param httpHeaders
   * @returns
   */
  public customPost(
    path: string,
    requestObject: any,
    httpHeaders: any = {}
  ): Observable<any> {
    return this.http
      .post(path, requestObject, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(retry(0), shareReplay());
  }

  /**
   * Custom Put Request
   * @param path
   * @param requestObject
   * @param httpHeaders
   * @returns
   */
  public customFileUpload(
    path: string,
    requestObject: any,
    httpHeaders: any = {}
  ): Observable<any> {
    return this.http
      .post(path, requestObject, {
        headers: httpHeaders,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(retry(0), shareReplay());
  }
}
