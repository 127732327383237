import { Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { TokenPayload } from '../models/jwt-payload';
import { SessionUserType } from '../models/session-type';
import moment from 'moment';
import { UserBranchRoleAndPrivilege } from 'src/app/shared/model/roles-and-right/branch-role-and-privilage';

@Injectable()
export class UtilityService {
  public myData = new BehaviorSubject(null);
  public isAcess = new BehaviorSubject(false);

  private rolesAndRights = new BehaviorSubject<UserBranchRoleAndPrivilege[]>(
    []
  );

  get currentRolesAndRights(): Observable<UserBranchRoleAndPrivilege[]> {
    return this.rolesAndRights.pipe(distinctUntilChanged());
  }

  constructor() {
    this.initializeValue();
  }

  private initializeValue() {
    const rolesString = localStorage.getItem('organizationBranchRoles');

    try {
      if (rolesString != null) {
        const roles = JSON.parse(rolesString);
        this.rolesAndRights.next(roles);
      }
    } catch (error) {}
  }

  /**
   *
   * @param res
   * @returns
   */
  public static convertObjectToJson(res: { body: any }): any {
    return JSON.parse(JSON.stringify(res.body));
  }

  /*
    Read user from local storage.
  */
  public static getUser(): any {
    let user: any = null;

    try {
      user = JSON.parse(this.getLocalStorage('user_info') || '{}');
    } catch (e) {
      console.error('Get User Error => ', e);
    }
    return user;
  }

  /**
   *
   * @param property
   * @param value
   */
  public static setLocalStorage(property: string, value: string): void {
    localStorage.setItem(property, value);
  }

  /**
   *
   * @param array
   */
  public static setMultiLocalStorage(array: any[]): void {
    array.forEach((item) => {
      localStorage.setItem(item[0], item[1]);
    });
  }

  /**
   *
   * @param property
   * @returns
   */
  public static getLocalStorage(property: string): any {
    if (localStorage.getItem(property)) {
      return localStorage.getItem(property);
    } else {
      return null;
    }
  }

  /**
   *
   * @param property
   */
  public static removeItemLocalStorage(property: string) {
    if (localStorage.getItem(property)) {
      localStorage.removeItem(property);
    }
  }

  /**
   *
   */
  public static clearLocalStorage() {
    localStorage.clear();
  }

  /**
   *
   * @param url
   * @param params
   * @returns
   */
  public static createParameterizedUrl(url: string, params: any): string {
    let newUrl = url;
    Object.keys(params).forEach((param) => {
      const regex = new RegExp('{' + param + '}', 'g');
      newUrl = newUrl.replace(regex, params[param]);
    });
    return newUrl;
  }

  /**
   *
   * @param data
   * @returns
   */
  public static convertToSlug(data: any) {
    if (data) {
      return data
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    }
    return data;
  }

  // New Functions

  public static getAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken;
  }

  public static isAuthenticated() {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken !== null) {
      return true;
    }

    return false;
  }

  public static getCurrentBranch() {
    const branchId = localStorage.getItem('organizationBranchId');
    return branchId;
  }

  public static setCurrentBranch(branchId: string) {
    localStorage.setItem('organizationBranchId', branchId);
  }

  static get sessionUserType(): SessionUserType {
    const token = this.getAccessToken();
    if (token) {
      try {
        const decoded: TokenPayload = jwtDecode<TokenPayload>(token);
        return decoded.UserType ?? SessionUserType.None;
      } catch (error) {
        return SessionUserType.None;
      }
    }
    return SessionUserType.None;
  }

  static get isAccessTokenValid(): boolean {
    const token = this.getAccessToken();
    if (token) {
      try {
        const decoded: TokenPayload = jwtDecode<TokenPayload>(token);
        const expiryMoment = moment.unix(decoded.exp ?? 0);
        const now = moment();


        if (now.isBefore(expiryMoment)) {
          return true;
        }
      } catch (error) {
      }
    }
    return false;
  }

  public setOrganizationRolesAndRights(roles: UserBranchRoleAndPrivilege[]) {
    
    const combinedData = {
      "RoleNames": roles.map(item => item.roleName),
      "Privileges": [...new Set(roles.flatMap(item => item.privilege))]
  };

    localStorage.setItem('organizationBranchRoles', JSON.stringify(combinedData));
    this.rolesAndRights.next(roles);
  }

  public setUserDepartment(apiRes : any){
    localStorage.setItem('userDepartment', JSON.stringify(apiRes));
  }
}
