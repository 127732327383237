@if(loading) {
<div fxFlex="row" fxLayoutAlign="center">
  <mat-spinner [diameter]="40" />
</div>
}

<!-- Data -->
@if (this.doctorProfileData || !loading) {

<div fxLayout="column">
  <mat-card class="details-card trinta-card mb-25 bg-white border-none d-block">
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="hoverable">
            <input
              hidden
              (change)="onFileSelected1($event)"
              #fileInput1
              type="file"
              accept="image/*"
            />

            <img
              class="profile-img"
              [src]="
                this.userImage != null
                  ? this.userImage
                  : './assets/images/pp.png'
              "
              alt="User Image"
              width="150"
            />

            <span class="middle" (click)="fileInput1.click()">
              <mat-icon class="color-primary">photo_camera</mat-icon>
            </span>
          </div>

          <p class="text-center">
            {{ this.userInfo.firstName }} {{ this.userInfo.lastName }}
          </p>

          <p class="text-center">
            {{ this.doctorProfileData.degree }}
          </p>

          <p class="text-center">
            {{ this.doctorProfileData.gender }}
          </p>

          <p class="text-center">
            <mat-icon color="primary">email</mat-icon>
            {{ this.userInfo.email }}
          </p>

          <p class="text-center">
            <mat-icon color="primary">phone</mat-icon>
            {{ this.userInfo.phoneNumber }}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-tab-group>
    <mat-tab label="Personal Information">
      <mat-card class="trinta-card mb-25 bg-white border-none d-block">
        <mat-card-header>
          <mat-card-title>
            <h5 class="mb-0 mt-0">Education</h5>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="education-content">
          <form [formGroup]="personaldataFormGroup">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="0px">
              <!-- Degree -->
              <div
                class="form-group"
                fxFlex="33.33"
                fxFlex.lt-md="50"
                fxFlex.xs="100"
              >
                <mat-form-field class="no-icon">
                  <mat-label> Highest Professional Degree </mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="degree"
                    [(ngModel)]="this.doctorProfileData.degree"
                  />
                </mat-form-field>
              </div>

              <!-- Speciality -->
              <div
                class="form-group"
                fxFlex="33.33"
                fxFlex.lt-md="50"
                fxFlex.xs="100"
              >
                <!-- <mat-label fxFlex="20" fxFlex.lt-sm="30" > Description </mat-label> -->
                <mat-form-field class="no-icon">
                  <mat-label>Doctor Specialities </mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="speciality"
                    [(ngModel)]="this.doctorProfileData.specialities"
                  />
                </mat-form-field>
              </div>

              <!-- Institute -->
              <div
                class="form-group"
                fxFlex="33.33"
                fxFlex.lt-md="50"
                fxFlex.xs="100"
              >
                <mat-form-field class="no-icon">
                  <mat-label> Institution Name </mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="institute"
                    [(ngModel)]="this.doctorProfileData.instituationName"
                  />
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card-content>

        <mat-card-header>
          <mat-card-title>
            <h5 class="mb-0 mt-0">Professional</h5>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="professional-content">
          <form [formGroup]="professionaldataFormGroup">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="0px">
              <!-- Licence No -->
              <div
                class="form-group"
                fxFlex="33.33"
                fxFlex.lt-md="50"
                fxFlex.xs="100"
              >
                <mat-form-field class="no-icon">
                  <mat-label> Licence No </mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="licence"
                    [(ngModel)]="this.doctorProfileData.licenceNo"
                  />
                </mat-form-field>
              </div>

              <!-- Registration No -->
              <div
                class="form-group"
                fxFlex="33.33"
                fxFlex.lt-md="50"
                fxFlex.xs="100"
              >
                <mat-form-field class="no-icon">
                  <mat-label> Registration No </mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="registration"
                    [(ngModel)]="this.doctorProfileData.registrationNo"
                  />
                </mat-form-field>
              </div>

              <!-- Board Membership -->
              <div
                class="form-group"
                fxFlex="33.33"
                fxFlex.lt-md="50"
                fxFlex.xs="100"
              >
                <mat-form-field class="no-icon">
                  <mat-label> Board Membership </mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="boardmembership"
                    [(ngModel)]="this.doctorProfileData.boardMembership"
                  />
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card-content>

        <mat-card-header>
          <mat-card-title>
            <h5 class="mb-0 mt-0">Facility Information</h5>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="clinic-content">
          <form [formGroup]="officeaddressFormGroup">
            <div
              fxLayout="row"
              fxLayoutAlign="start start"
              fxLayout.xs="column"
              fxLayoutAlign.xs="start stretch"
              fxLayoutGap.xs="20px"
            >
              <div
                style="position: relative; width: 300px; height: 300px"
                ngStyle.xs="align-self: center"
              >
                <input
                  hidden
                  (change)="onFileSelected($event)"
                  #fileInput
                  type="file"
                  accept="image/*"
                />
                <img [src]="imageSrc" width="300px" />

                <button
                  *ngIf="isAdmin"
                  style="position: absolute; top: 0; right: 0"
                  type="button"
                  mat-icon-button
                  (click)="fileInput.click()"
                  color="primary"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </div>

              <div
                style="flex: 1"
                fxLayout="row wrap"
                fxLayout.xs="column"
                fxLayoutGap.xs="0px"
              >
                <div
                  class="form-group"
                  fxFlex="33.33"
                  fxFlex.lt-md="50"
                  fxFlex.xs="100"
                >
                  <mat-form-field class="no-icon">
                    <mat-label> Clinic Name </mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="clinicName"
                      placeholder="Clinic Name"
                      [(ngModel)]="this.doctorProfileData.clinicName"
                    />
                  </mat-form-field>
                </div>
                <!-- Street field -->
                <div
                  class="form-group"
                  fxFlex="33.33"
                  fxFlex.lt-md="50"
                  fxFlex.xs="100"
                >
                  <mat-form-field class="no-icon">
                    <mat-label> Address line 1 </mat-label>
                    <input
                      formControlName="Street1"
                      maxlength="256"
                      type="text"
                      matInput
                      #ngxPlaces="ngx-places"
                      [options]="options"
                      ngx-gp-autocomplete
                      (onAddressChange)="selectAddress($event)"
                      id="search"
                      [(ngModel)]="this.address1"
                    />
                  </mat-form-field>
                </div>
                <!-- Street2 field -->
                <div
                  class="form-group"
                  fxFlex="33.33"
                  fxFlex.lt-md="50"
                  fxFlex.xs="100"
                >
                  <mat-form-field class="no-icon">
                    <mat-label>Address line 2</mat-label>
                    <input
                      formControlName="Street2"
                      matInput
                      [(ngModel)]="this.locality"
                    />
                  </mat-form-field>
                </div>
                <!-- City field -->
                <div
                  class="form-group"
                  fxFlex="33.33"
                  fxFlex.lt-md="50"
                  fxFlex.xs="100"
                >
                  <mat-form-field class="no-icon">
                    <mat-label> City </mat-label>
                    <input
                      formControlName="City"
                      matInput
                      [(ngModel)]="this.city"
                    />
                  </mat-form-field>
                </div>
                <!-- State Field -->
                <div
                  class="form-group"
                  fxFlex="33.33"
                  fxFlex.lt-md="50"
                  fxFlex.xs="100"
                >
                  <mat-form-field class="no-icon">
                    <mat-label> State </mat-label>
                    <input
                      formControlName="State"
                      matInput
                      [(ngModel)]="this.state"
                    />
                  </mat-form-field>
                </div>
                <!-- country Field -->
                <div
                  class="form-group"
                  fxFlex="33.33"
                  fxFlex.lt-md="50"
                  fxFlex.xs="100"
                >
                  <mat-form-field class="no-icon">
                    <mat-label> Country </mat-label>
                    <input
                      formControlName="Country"
                      matInput
                      [(ngModel)]="this.country"
                    />
                  </mat-form-field>
                </div>
                <!-- Pincode field -->
                <div
                  class="form-group"
                  fxFlex="33.33"
                  fxFlex.lt-md="50"
                  fxFlex.xs="100"
                >
                  <mat-form-field class="no-icon">
                    <mat-label> Zip </mat-label>
                    <input
                      formControlName="Zip"
                      matInput
                      [(ngModel)]="this.postalCode"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="isAdmin" fxLayout="row" fxLayoutAlign="center">
              <button
                mat-flat-button
                color="primary"
                (click)="updateProfile()"
                [disabled]="!canProceed()"
              >
                Next
              </button>
            </div>
          </form>
          @if(this.doctorSubscriptionDetails.status!=='authenticated' && this.doctorSubscriptionDetails.status!=='active' && this.paymentFeatureFlag!=='none'){
            <p class="text-center">Contact admin, subscription is not active!</p>
          }
        </mat-card-content>
      </mat-card>
    </mat-tab>
    @if(this.isOrgAdmin && this.paymentFeatureFlag!=='None' && (hasPermission('PAYMENT_EDIT') || hasPermission('PAYMENT_ADD'))){
      <mat-tab label="Subscription Details">
        @if(this.doctorSubscriptionDetails && this.paymentFeatureFlag!=='None'){

        <mat-card
          class="trinta-card invoice-details-card mb-25 bg-white border-none d-block"
        >
          <mat-card-content>
            <div class="invoice-info">
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <span class="d-block fw-medium heading-font-family fs-15">
                    Assignment
                    <span class="d-block text-black fw-medium">
                      {{ this.doctorSubscriptionDetails.planName }}
                    </span>
                  </span>
                </div>
                <div class="col-lg-4 col-sm-6">
                  <span class="d-block fw-medium heading-font-family fs-15">
                    Subscription Status
                    <span class="d-block text-black fw-medium">
                      {{ this.doctorSubscriptionDetails.status }}
                    </span>
                  </span>
                </div>

                <div class="col-lg-4 col-sm-6">
                  <span class="d-block fw-medium heading-font-family fs-15">
                    Amount
                    <span class="d-block text-black fw-medium">
                      Rs. {{ this.doctorSubscriptionDetails.amount }}
                    </span>
                  </span>
                </div>

                <div class="col-lg-4 col-sm-6">
                  <span class="d-block fw-medium heading-font-family fs-15">
                    Start Date
                    <span class="d-block text-black fw-medium">
                      {{ this.doctorSubscriptionDetails.startAt | date: 'dd-MMM-YYYY' }}
                    </span>
                  </span>
                </div>
                <div class="col-lg-4 col-sm-6">
                  <span class="d-block fw-medium heading-font-family fs-15">
                    End Date
                    <span class="d-block text-black fw-medium">
                      {{ this.doctorSubscriptionDetails.endAt | date: 'dd-MMM-YYYY' }}
                    </span>
                  </span>
                </div>
                <div class="col-lg-4 col-sm-6">
                  <span class="d-block fw-medium heading-font-family fs-15">
                    Next Billing Date
                    <span class="d-block text-black fw-medium"> 25-12-2010 </span>
                  </span>
                </div>
              </div>
            </div>
          </mat-card-content>
          <button mat-flat-button color="primary">Update</button>
          <button mat-stroked-button (click)="CancelSubscription()">Cancel</button>
        </mat-card>
        }
      </mat-tab>
    }
  </mat-tab-group>

  <mat-card class="timing-card trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
      <mat-card-title>
        <h5 class="mb-0 mt-0">Your Schedule</h5>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxlayoutAlign="start stretch" fxLayoutGap="25px">
        <form *ngIf="isAdmin" [formGroup]="clinicTimeFormGroup">
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="0px">
            <div
              class="form-group"
              fxFlex="33.33"
              fxFlex.lt-md="50"
              fxFlex.xs="100"
            >
              <mat-form-field class="no-icon">
                <mat-label>Day</mat-label>
                <mat-select
                  formControlName="day"
                  (selectionChange)="dayChange($event)"
                >
                  @for (day of days; track day) {
                  <mat-option [value]="day">
                    {{ day.viewValue }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="form-group"
              fxFlex="33.33"
              fxFlex.lt-md="50"
              fxFlex.xs="100"
            >
              <mat-form-field class="no-icon">
                <mat-label>Average Duration</mat-label>
                <mat-select
                  formControlName="duration"
                  (selectionChange)="durationChange($event)"
                >
                  @for (duration of durations; track duration) {
                  <mat-option [value]="duration">
                    {{ duration }} Minutes
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="form-group"
              fxFlex="33.33"
              fxFlex.lt-md="50"
              fxFlex.xs="100"
            >
              <mat-form-field class="no-icon">
                <mat-label>Max. Online Appointments</mat-label>
                <mat-select
                  formControlName="ratio"
                  (selectionChange)="ratioChange($event)"
                >
                  @for (ratio of ratios; track ratio) {
                  <mat-option [value]="ratio">
                    {{ ratio }} Patients
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="form-group"
              fxFlex="33.33"
              fxFlex.lt-md="50"
              fxFlex.xs="100"
            >
              <mat-form-field class="no-icon">
                <mat-label>Start Time</mat-label>
                <mat-select
                  formControlName="startTime"
                  (selectionChange)="timeChange($event, 'start')"
                >
                  @for (time of times; track time) {
                  <mat-option [value]="time.value">
                    {{ time.viewValue }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="form-group"
              fxFlex="33.33"
              fxFlex.lt-md="50"
              fxFlex.xs="100"
            >
              <mat-form-field class="no-icon">
                <mat-label>End Time</mat-label>
                <mat-select
                  formControlName="endTime"
                  (selectionChange)="timeChange($event, 'end')"
                >
                  @for (time of times; track time) {
                  <mat-option [value]="time.value">
                    {{ time.viewValue }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="form-group"
              fxFlex="33.33"
              fxFlex.lt-md="50"
              fxFlex.xs="100"
            >
              <mat-form-field class="no-icon">
                <mat-label>Break Start Time</mat-label>
                <mat-select
                  formControlName="breakStartTime"
                  (selectionChange)="timeChange($event, 'breakstart')"
                >
                  @for (time of timeSlotsBreak; track time) {
                  <mat-option [value]="time.value" [disabled]="time.disabled">
                    {{ time.viewValue }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="form-group"
              fxFlex="33.33"
              fxFlex.lt-md="50"
              fxFlex.xs="100"
            >
              <mat-form-field class="no-icon">
                <mat-label>Break End Time</mat-label>
                <mat-select
                  formControlName="breakEndTime"
                  (selectionChange)="timeChange($event, 'breakend')"
                >
                  @for (time of timeSlotsBreakEnd; track time) {
                  <mat-option [value]="time.value" [disabled]="time.disabled">
                    {{ time.viewValue }}
                  </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center">
            <button
              mat-button
              mat-flat-button
              color="primary"
              (click)="addSchedule()"
              [disabled]="clinicTimeFormGroup.invalid"
            >
              Save
            </button>
          </div>
        </form>

        <div>
          <ejs-schedule
            height="70vh"
            #scheduleObj
            [eventSettings]="eventSettings"
            [selectedDate]="selectedDate"
            (popupOpen)="onPopupOpen($event)"
            (actionComplete)="onActionComplete($event)"
            cssClass="custom-class"
            [timeScale]="timeScale1"
            style="overflow: visible"
            [workHours]="scheduleHours"
            (eventRendered)="applyCategoryColor($event)"
          >
            <e-views>
              <!-- <e-view option="Day" [interval]="1"></e-view> -->
              <e-view
                option="Week"
                [format]="formatString"
                [interval]="1"
              ></e-view>
              <!-- <e-view option="Month" [interval]="1"></e-view> -->
            </e-views>
          </ejs-schedule>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

}
