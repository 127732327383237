import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { config } from 'src/app/configs/config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseResponseWrapper, ResponseWrapper } from '../../model/common';
import { Staff } from '../../model/organization/staff';
import { StaffAddDto } from 'src/app/models/dto/staff-add-dto';
import { AddStaffDepartmentAndSchedulePayload } from '../../model/staff-department/add-staff-depatment-schedule';

@Injectable({
  providedIn: 'root',
})
export class StaffManagementService {
  constructor(private http: HttpClient) {}

  addStaff(data: StaffAddDto): Observable<ResponseWrapper<any>> {
    const url = `${environment.baseURL}${config.partialUrls.AddStaff}`;
    return this.http
      .post<ResponseWrapper<null>>(url, data)
      .pipe(
        map((response) => {
          return response;
        })
      )
      .pipe(
        catchError((error) => {
          console.log('Error adding staff: ', error);
          return throwError(() => error);
        })
      );
  }

  // New
  getBranchStaffList(orgBranchId: string): Observable<Staff[]> {
    const url = `${environment.baseURL}${config.partialUrls.GetAllStaff}`;
    return this.http
      .get<ResponseWrapper<Staff[]>>(url, {
        params: {
          branchId: orgBranchId,
        },
      })
      .pipe(
        map((response: ResponseWrapper<Staff[]>) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branch staff:', error);
          return throwError(() => error);
        })
      );
  }

  getBranchDepartmentStaffSchedule(
    orgBranchId: string,
    departmentId: string
  ): Observable<any> {
    const url = `${environment.baseURL}${config.partialUrls.GetBranchDepartmentStaffSchedule}`;
    return this.http
      .get<ResponseWrapper<any>>(url, {
        params: {
          organizationBranchId: orgBranchId,
          departmentId: departmentId,
        },
      })
      .pipe(
        map((response: ResponseWrapper<any>) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branch staff:', error);
          return throwError(() => error);
        })
      );
  }

  addStaffDeptAndSchedule(
    payload: AddStaffDepartmentAndSchedulePayload[]
  ): Observable<any> {
    const url = `${environment.baseURL}${config.partialUrls.AddStaffDeptAndSchedule}`;
    return this.http
      .post<ResponseWrapper<any>>(url, payload)
      .pipe(
        map((response: ResponseWrapper<any>) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branch staff:', error);
          return throwError(() => error);
        })
      );
  }

  getOrgBranchScheduleList(orgBranchId: string): Observable<Staff[]> {
    const url = `${environment.baseURL}${config.partialUrls.GetOrgBranchSchedule}`;
    return this.http
      .get<ResponseWrapper<any>>(url, {
        params: {
          organizationBranchId: orgBranchId,
        },
      })
      .pipe(
        map((response: ResponseWrapper<any>) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branch staff:', error);
          return throwError(() => error);
        })
      );
  }

  getBranchStaffNames(orgBranchId: string): Observable<Staff[]> {
    const url = `${environment.baseURL}${config.partialUrls.GetAllStaffNames}`;
    return this.http
      .get<ResponseWrapper<Staff[]>>(url, {
        params: {
          branchId: orgBranchId,
        },
      })
      .pipe(
        map((response: ResponseWrapper<Staff[]>) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branch staff names:', error);
          return throwError(() => error);
        })
      );
  }
}
