import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UtilityService } from '../services/utility.service';
import { RazorpayService } from 'src/app/shared/services/razorpay-sevice/razorpay.service';
@Injectable({
  providedIn: 'root',
})
export class RazorpaySubscriptionGuard implements CanActivate {

  constructor(
    private router: Router,
    private razorpayService:RazorpayService
  ) {}

  canActivate(): boolean {
    // Check if the user is logged in
    if (UtilityService.getLocalStorage('accessToken')) {
      var subStatus = UtilityService.getLocalStorage('subscriptionStatus');
      var branchId = UtilityService.getLocalStorage('organizationBranchId')||null;
      var userInfo = UtilityService.getUser();
      // if(subStatus==='active' || subStatus===null){
      //   return true;
      // }else{
      //   this.router.navigate(['/doctor/profile-setting']);
      //   return false;
      // }
      // Check if the user is subscribed to a plan
      this.razorpayService.GetSubscriptionDetails(userInfo.id,branchId).subscribe(
        (response)=>{
          if(response.body.status==='active' || response.body.status==='authenticated' || response.body.status==='orgBranchId cannot be null' || response.body.status==='payment feature is turned off'){
            return true;
          }
          // If not subscribed, redirect to profile setting and return false
          this.router.navigate(['/doctor/profile-setting']);
          return false;
        },
        (error:any)=>{
          return false;
        }
      );
      return true;
    } else {
      // If not logged in, redirect to login page (optional)
      this.router.navigate(['/login']);
      return false;
    }
  }
}
