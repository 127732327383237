<div
    class="sidebar-area bg-white transition"
    [ngClass]="{'active': isToggled}"
>
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img src="assets/images/logo.png" alt="logo-icon">
            
        </a>
    </div>
    <div
        class="burger-menu transition cursor-pointer"
        [ngClass]="{'active': isToggled}"
        (click)="toggle()"
    >
        <span class="top-bar transition bg-dark d-block"></span>
        <span class="middle-bar transition bg-dark d-block"></span>
        <span class="bottom-bar transition bg-dark d-block"></span>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <span class="sub-title text-body">
                        APPS
                    </span>
                    <a *ngIf="OrgAdminRole" routerLink="/unidashboard/masterreport" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i-feather name="file-text"></i-feather>
                        <span class="title">
                            Master Report
                        </span>
                    </a>
                    <a routerLink="/unidashboard/patientoverview" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i-feather name="calendar"></i-feather>
                        <span class="title">
                            Patient Overview
                        </span>
                    </a>
                    <a routerLink="/unidashboard/performancereport" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i-feather name="users"></i-feather>
                        <span class="title">
                            Performance Report
                        </span>
                    </a>
                    <a *ngIf="OrgAdminRole || BranchAdminRole" routerLink="/unidashboard/financereport" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i-feather name="file-text"></i-feather>
                        <span class="title">
                            Finance Report
                        </span>
                    </a>
                    <a *ngIf="OrgAdminRole || BranchAdminRole" routerLink="/unidashboard/ipreport" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i-feather name="file-text"></i-feather>
                        <span class="title">
                            IP Report
                        </span>
                    </a>
                    <a *ngIf="OrgAdminRole" routerLink="/unidashboard/students" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i-feather name="file-text"></i-feather>
                        <span class="title">
                            View Students Data
                        </span>
                    </a>
                    <a routerLink="/dashboard/doctor" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i-feather name="home"></i-feather>
                        <span class="title">
                            Dashboard
                        </span>
                    </a>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>