import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { config } from 'src/app/configs/config';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisService {
  private baseUrl = environment.baseURL; // Replace with your actual base API URL
  private diagnosisApiUrl = '/api/Diagnosis';
  private ViewFamilyHistory = '/api/Patient';
  constructor(private http: HttpClient, private service: ApiService) {}

  private checkBoxValueSource = new BehaviorSubject<boolean>(false);
  checkBoxValue$ = this.checkBoxValueSource.asObservable();

  setCheckBoxValue(value: boolean): void {
    this.checkBoxValueSource.next(value);
  }
  

  addMedication(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/AddMedication`;
    return this.http.post(url, data);
  }

  addDoctorDiagnosis(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/AddDoctorDiagnosis`;

    return this.http.post(url, data);
  }

  listDiagnosis(patientId: string): Observable<any> {
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/ListDiagnosis?patientId=${patientId}`;
    const accessToken = localStorage.getItem('accessToken');

    // Include authorization header directly
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching diagnoses:', error);
        return throwError(error);
      })
    );
  }

  viewFamilyHistory(patientId: string): Observable<any> {
    const url = `${this.baseUrl}${this.ViewFamilyHistory}/ViewFamilyHistory?patientId=${patientId}`;
    const accessToken = localStorage.getItem('accessToken');

    // Include authorization header directly
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching diagnoses:', error);
        return throwError(error);
      })
    );
  }

  viewPrakrutiQuestions(): Observable<any> {
    const url = `${this.baseUrl}/api/Analysis/ViewPrakrutiQuestions`;
    const accessToken = localStorage.getItem('accessToken');

    // Include authorization header directly
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching Prakruti questions:', error);
        return throwError(error);
      })
    );
  }
  viewDiagnosisDetails(patientId: string): Observable<any> {
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/ViewDiagnosisDetails?patientId=${patientId}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }
  viewDiagnosisDetailsOpen(patientId: string): Observable<any> {
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/ViewDiagnosisDetailsOpen?patientId=${patientId}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }

  viewConsultationDetails(
    patientId: string,
    appointmentId: string
  ): Observable<any> {
    const url = `${this.baseUrl}/RequestConsultation/ViewConsultation?patientId=${patientId}&ConsultationId=${appointmentId}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }

  getDiagnosisViewMaster(
    type: string,
    search: string,
    doctorId: string
  ): Observable<any> {
    var doctorIdQuery = doctorId != '' ? `&doctorId=${doctorId}` : '';
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/ViewMaster?type=${type}&search=${search}${doctorIdQuery}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }


  getLabTestMaster(
    search: string,
  ): Observable<any> {
    const url = `${this.baseUrl}${this.ViewFamilyHistory}/GetLabTest?search=${search}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }

  getImageingTestMaster(
    search: string,
  ): Observable<any> {
    const url = `${this.baseUrl}${this.ViewFamilyHistory}/GetLabImaging?search=${search}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }



  getKriyaInstructions() {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return this.service.get(config.partialUrls.viewInstructions, { headers });
  }

  getProcedureViewMaster(search: string): Observable<any> {
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/ViewProcedure?search=${search}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }

  getMedicationViewMaster(search: string, doctorId: string): Observable<any> {
    var doctorIdQuery = doctorId != '' ? `&doctorId=${doctorId}` : '';
    const url = `${this.baseUrl}${this.diagnosisApiUrl}/ViewMedication?search=${search}${doctorIdQuery}`;
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching diagnosis details:', error);
        return throwError(error);
      })
    );
  }

  getDiagnosisData() {
    const accessToken = localStorage.getItem('accessToken');

    // Include authorization header directly
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return this.service.get(config.partialUrls.getDiagnosisDetails, {
      headers,
    });
  }


  Adddiagnosisdata(data: any) {
    return this.service.post(config.partialUrls.Adddiagnosisdata, data);
  }

}
