import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
    // Observable to trigger refresh
    private refreshNeeded = new Subject<void>();
    refreshNeeded$ = this.refreshNeeded.asObservable();

  private permissions: string[] = [];
  private roles: string[] = [];

  constructor() {
   this.loadData();
  }

  private loadData(): void {
    // Delay the loading of permissions and roles slightly to ensure data is available
    setTimeout(() => {
      this.loadPermissions();
      this.loadRoles();
    }, 1000); // Adjust this delay based on your needs
  }

  private loadPermissions(): void {
    const storedPermissions = UtilityService.getLocalStorage('organizationBranchRoles');
    this.permissions = storedPermissions ? JSON.parse(storedPermissions).Privileges : [];
  }

  hasPermission(permission: string): boolean {
    this.loadPermissions();
    return this.permissions.includes(permission);
  }

  private loadRoles(): void {
    const storedRoles = UtilityService.getLocalStorage('organizationBranchRoles');
    this.roles = storedRoles ? JSON.parse(storedRoles).RoleNames : [];
  }
  
  hasRole(role: string): boolean {
    this.loadRoles();
  return this.roles.includes(role);
  }

  hasRoleAndPrivileges() : boolean{
    return this.roles.length > 0
  }

   // Method to trigger refresh
   triggerRefresh() {
    this.refreshNeeded.next();
  }
}
