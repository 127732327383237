import { Injectable } from '@angular/core';
import { config } from 'src/app/configs/config';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})

export class RazorpayService{
    
    constructor(private service:ApiService) {}

    createSubscription(obj:any){
        return this.service.post(config.partialUrls.CreateRazorpaySubscription, obj);
    }
    AuthenticatePayment(obj:any){
      return this.service.post(config.partialUrls.AuthenticateRazorpayPayment, obj);
    }
    AddMapping(obj:any){
      return this.service.post(config.partialUrls.AddUserSubscriptionMapping, obj);
    }
    GetSubscriptionDetails(userId:any,organizationBranchId:any){
      return this.service.get(config.partialUrls.GetSubscriptionDetails+"?userId="+userId+"&organizationBranchId="+organizationBranchId,{});
    }
    CancelSubscription(userId:any,organizationBranchId:any){
      return this.service.get(config.partialUrls.CancelSubscription+"?userId="+userId+"&organizationBranchId="+organizationBranchId,{});
    }

}